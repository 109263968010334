$search-place-link-color: #ccc !default;
$search-place-link-color-hover: #aaa !default;
$search-place-font-size-large: rem-calc(18) !default;
$search-place-font-size-small: rem-calc(16) !default;

.comp-search-place-info {

    margin-bottom: rem-calc(35);

    margin-top: rem-calc(5) !important;

    p {
        font-size: $search-place-font-size-large;

        @include breakpoint(small down) {
            font-size: $search-place-font-size-small;
        }
    }

    a {
        font-weight: bold;
        color: $search-place-link-color;
        padding-left: rem-calc(10);

        @include breakpoint(small down) {

            background-size: $search-place-font-size-small;
        }

        &:hover {
            color: $search-place-link-color-hover
        }


        &::before {
            content: "\EA38";
            font-family: 'Icomoon';
            margin-right: rem-calc(10);
            font-size: rem-calc(22);
        }
    }

    .pulsate {
        animation: pulsate 1.5s ease-out;
        animation-iteration-count: 1;
        opacity: 1;
    }

    @keyframes pulsate {
        50% {
            opacity: .5;
        }
    }
}
