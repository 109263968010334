@import '../../../node_modules/klaro/src/scss/vars';

$notice-title-font-size: rem-calc(15) !default;
$notice-max-width: rem-calc(450) !default;


.klaro {
    &.cookie-banner-container{
        .cookie-modal,
        .cookie-notice {
            p,
            strong,
            h1,
            h2,
            ul,
            li {
                @include var(color, text-color);
            }

            .cm-header,
            .cn-body {
                .title-wrapper {
                    @include flex;
                    @include flex-align(justify);

                    .title {
                        font-size: $notice-title-font-size;
                    }
                }
                .body {
                    font-size: $notice-title-font-size;
                }
            }
        }

        .cookie-notice {
            a {
                color: $link-color;
            }

            &:not(.cookie-modal-notice) {
                @include var(background-color, white1);
                max-width: $notice-max-width;

                .cn-body {

                    .body {
                        font-size: $notice-title-font-size;
                    }

                    .cn-ok {
                        //@include flex-direction(row-reverse);
                        @include button-group(cn-ok);
                        flex-direction: row-reverse;
                        @include flex-align(justify);

                        margin-top: rem-calc(20);

                        a,
                        div {
                            margin-top: 0;
                        }

                        a, button {
                            border-radius: 6px;
                        }

                        a {
                            &.cm-link {
                                display: none;
                                max-width: max-content;
                                margin-bottom: 0;
                                @include button-hollow;
                                @include button-hollow-style($button-background, 0px, 1px);
                            }
                        }

                        .cn-buttons {
                            @include flex;
                            @include flex-direction(row-reverse);
                            @include flex-align(justify);
                            flex: 1;
                            margin-right: 5px;

                            button {
                                &.cm-btn {
                                    margin-top: 0;
                                    padding: 10px;
                                    width: 100%;
                                    height: auto;
                                    margin-bottom: 0;
                                }

                                &.cn-decline {
                                    @include button-hollow;
                                    @include button-hollow-style($button-background, 0px, 1px);
                                }
                            }
                        }
                    }
                }
            }

            .cn-ok {
                @include button-group(cn-ok);

                .cm-btn {

                    @include button();

                    &.cm-btn-success {
                        @include button-style($button-background, $button-hover, $white);
                        margin-right: 5px;
                    }
                }

                a.cm-link {
                    margin-top: 0;
                    @include button();
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .cookie-modal {
            .cm-modal {
                &.cm-klaro {
                    @include var(background-color, white1);
                    @include var(color, text-color);

                    .cm-header {
                        h1 {
                            &.title {
                                padding-right: 0;
                                //font-size: rem-calc(1);
                                font-weight: normal;
                            }
                        }
                    }

                    .cm-footer {
                        .cm-footer-buttons {
                            .cm-btn {
                                @include button();
                                border-radius: 6px;
                                width: auto;
                                height: auto;

                                &.cm-btn-success {
                                    @include button-style($button-background, $button-hover, $white);
                                }

                                &.cm-btn-decline {
                                    @include button-hollow;
                                    @include button-hollow-style($button-background, 0px, 1px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
