$search-card-gutter: rem-calc(20) !default;
$main-background-image-url: '/static/assets/images/header.jpg' !default;
$top-bar-height-calc: rem-calc(68) !default;
$top-bar-height-calc: rem-calc(68) !default;
$search-wrapper-top-negative-plain: -39.5 !default;
$search-input-height: rem-calc(60);
$search-input-offset-height: rem-calc(12);
$search-card-gutter: rem-calc(20) !default;
$search-place-font-size-large: rem-calc(18) !default;
$search-place-font-size-small: rem-calc(16) !default;

.schub .search {
    background-position-y: 80%;
}

.search {
    background-image: url($main-background-image-url);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    z-index: 10;
    padding-top: 100vh;
    transition: height 1s, padding-top 1s;

    .main-image {
        width: 100%;
        height: auto;
    }

    .search-logo {
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        height: 30%;
        position: absolute;
        top: $top-bar-height-calc;
        width: 100%;

        @include breakpoint(small down) {
            height: 25%;
        }

        @include breakpoint(medium down) {
            @include breakpoint(landscape) {
                display: none;
            }
        }
    }

    .load-more-wrapper {
        text-align: center;
        margin: 60px 0 100px;

    }

    .load-more {
        display: inline-block;
        background: white;
        font-family: 'TheMixC5-SemiBold';
        font-size: 17px;
        padding: 20px 35px;
        color: #2846AA;
        border: 2px solid #2846AA;
        border-radius: 4px;
        width: auto;
        cursor: pointer;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }

        &:hover {
            border-color: #061D67;
            color: #061D67;
        }
    }

    &.has-searched {

        height: 261px;
        padding-top: 261px;


        .search-title {
            display: none;
        }


        .search-input-wrapper {
            max-width: calc(1260px);

            @include breakpoint(medium up) {
                padding-top: rem-calc(44px);
            }

            @include breakpoint(1350px down) {
                max-width: 88%;
            }
        }

        .input-auto-suggest {
            position: relative;
            max-width: 1150px;
        }

        @include breakpoint(small down) {
            .input-auto-suggest {
                position: absolute;
            }
        }


        .search-input-sticky-wrapper {
            margin-top: 20px;
        }

    }

    &:not(.has-searched) {
        .footer {
            margin-top: 0;
        }
    }

    .project-boxes {
        overflow: hidden;

        .box-row {
            @include breakpoint(medium up) {
                display: flex;
            }
        }

        .box-image {
            @include breakpoint(medium up) {
                width: 50%;
            }
            display: flex;
        }

        img {
            min-width: 100%;
            max-width: 100%;
            height: auto;

            @include breakpoint(medium down) {
                max-width: 100%;
            }

            @include breakpoint(small down) {
                max-width: 100%;
            }

            @include breakpoint(1225px down) {
                object-fit: cover;
            }
        }

        .box-text.green {
            background: #007844;
            color: white;
        }

        .box-text.white {
            background: white;
            color: black;
        }

        .box-text.blue {
            background: #2846AA;
            color: white;
        }

        .box-text.lightgreen {
            background: #DDF2CD;
            color: black;
        }

        .box-text.lightblue {
            background: #E5F1FC;
            color: black;
        }

        .box-text {
            @include breakpoint(medium up) {
                width: 50%;
            }
            padding: 60px;

            @include breakpoint(small down) {
                padding: 24px 20px 46px 20px;
            }

            @include breakpoint(2560px up) {
                padding: 120px 355px 230px 355px;
            }
            h2 {
                font-size: 45px;
                font-family: 'TheMixC5-Bold';

                @include breakpoint(medium down) {
                    font-size: 25px;
                }
            }
            p {
                font-family: 'TheMixC5-Plain';
                font-size: 18px;
                max-width: 570px;
                margin-top: 24px;

                @include breakpoint(medium down) {
                    font-size: 16px;
                }
            }
        }

        .box-row:nth-child(2n) {
            @include breakpoint(medium up) {
                flex-direction: row-reverse;
            }
        }
    }

    .search-title-wrapper {
        height: auto;
        margin: 0 auto;
        width: 100%;
        max-width: 850px;

        @include breakpoint(large down) {
            width: 80%;

            @include breakpoint(landscape) {
                width: 95%;
            }
        }
        @include breakpoint(medium down) {
            width: 90%;
        }
        @include breakpoint(small down) {
            width: 90%;
            padding: 0;
        }

        .search-title {
            color: $search-title-color;
            padding-bottom: 40px;
            font-size: rem-calc(60);
            font-family: 'TheMixC5-Bold';
            font-weight: 700;
            line-height: 1;

            @include breakpoint(small down) {
                font-size: rem-calc(30);
                width: 100%;
            }
        }
    }

    $one-pixel-calc: rem-calc(1);

    .jump-mark-pixel {
        height: 0;
        width: 0;
        position: absolute;
        top: calc(100vh + #{$search-card-gutter} - (#{$one-pixel-calc} * 2));
        left: 0;
    }

    .viewport-offset-pixel {
        height: 1px;
        width: 1px;
        position: absolute;
        top: calc(100vh - #{$top-bar-height-calc} - (#{$one-pixel-calc} * 2));
        left: 0;
    }

    .no-farms-found {
        font-size: $search-place-font-size-large;

        @include breakpoint(small down) {
            font-size: $search-place-font-size-small;
        }
    }
}

.search-container {
    .loader-container {
        margin-top: rem-calc(30);
    }
}

.search-results-title {
    font-size: rem-calc(45);
    margin-bottom: 43px;
    font-family: 'TheMixC5-Bold';
    padding: 10px;

    @include breakpoint(small down) {
        font-size: 25px;
        padding-left: 0;
    }
}


.search--iframe {
    height: 0;

    .map-wrapper {
        top: 90px !important;
        right: 20px;
    }

    .content-wrapper .location-share-comp .share-location-wrapper {
        top: 90px !important;
        right: 70px !important;
        border-radius: 100%;
    }

    @supports (-webkit-touch-callout: none) {
        .search-input-wrapper .input-group .input-group-button .submit-search,
        .search-input-wrapper .input-group .input-group-button .reset-search {
            top: -65px;
        }
    }

    @supports not (-webkit-touch-callout: none) {
        .search-input-wrapper .input-group .input-group-button .submit-search,
        .search-input-wrapper .input-group .input-group-button .reset-search {
            top: -16px;
            margin-right: -10px;
        }
    }

    .search-input-wrapper .input-group .input-group-button .submit-search,
    .search-input-wrapper .input-group .input-group-button .reset-search  {
        @include breakpoint(small down) {
            bottom: 70px;
            height: 50px;
            width: 56px;
        }

        @include breakpoint(medium up) {
            width: 60px;
            height: 60px;
            top: 17px;
            right: 20px;
            position: absolute;
        }
    }

    .input-group {
        display: block;
    }

    .search-input-wrapper {
        margin: unset;
    }

    .input-group {
        @include breakpoint(small down) {
            width: 100%;
            margin-left: -3px;
            display: list-item;
            list-style: none;
            margin-top: -20px;
        }

        & > :first-child {
            border: 1px solid $input-border-color;
        }
    }

    .search-bar {
        max-width: 100%;
    }

    .filter-wrapper, .filter-wrapper-mobile {
        margin-top: 10px;
    }

    .input-group {
        @include breakpoint(small down) {
            width: unset;
            margin-left: unset;
            display: unset;
            list-style: unset;
            margin-top: unset;
        }
    }

    .filter-wrapper, .filter-wrapper-mobile {
        margin-left: 0;
    }

    .search-title-wrapper {
        display: none;
    }

    .search-header {
        top: 0;
    }

    .has-searched .search-input-sticky-wrapper {
        margin-top: 0;
    }

    &.has-searched {
        padding-top: rem-calc(150);
        background-image: none;

        .search-input-wrapper {
            max-width: 100%;
            @include breakpoint(medium up) {
                padding-top: rem-calc(20);
            }
        }


        .search-input-sticky-wrapper {
            margin-top: 20px;
        }
    }
    

    .search-wrapper .search-input-sticky-wrapper {
        margin-top: 0;
    }

    .result-wrapper {
        $search-card-gutter-calc: rem-calc($search-card-gutter);

        margin-top: rem-calc(20px);
        margin-bottom: rem-calc(47px);
        min-height: calc(100vh - #{$top-bar-height-calc} - #{$search-input-height} - #{$search-input-offset-height} - #{$search-card-gutter-calc} - 67px);
    }

    .jump-mark-pixel,
    .viewport-offset-pixel {
        left: -999em;
        top: 0;
    }
}