$nav-border-color: rgba(124, 182, 79, .2784) !default; // #86a65f - 27.84%
$nav-link-color: rgb(41, 41, 41) !default; // #292929
$nav-link-hover-color: rgb(174, 174, 174) !default; // #aeaeae
$nav-transition: left .2s, height 0.2s !default;
$nav-sm-width: 35.75% !default;
$nav-md-width: 65% !default;
$nav-website-link-color: rgb(41, 41, 41) !default;
$nav-website-link-hover-color: rgb(41, 41, 41) !default;
$top-bar-background-color: rgb(174, 174, 174) !default;
$top-bar-color: #ccc !default;
$main-navigation-height: rem-calc(68) !default;
$main-navigation-left-panel-width: rem-calc(200) !default;
$main-navigation-right-panel-width: rem-calc(122) !default;
$main-navigation-left-panel-small-width: rem-calc(50) !default;
$main-navigation-right-panel-small-width: rem-calc(50) !default;
$arrow-svg: url('/static/assets/images/arrow.svg') !default;
$menu-svg: url('/static/assets/images/menu.svg') !default;
$disabled-background: rgba(174, 174, 174, 1) !default;

body {
    position: relative;
    left: 0;
    transition: $nav-transition;
    overflow-x: hidden;

    .overlay-open {
        width: 100%;
        height: 10000px;
        position: fixed;
        z-index: 20;
        opacity: .5;
    }

    .overlay-closed {
        visibility: hidden;
    }

    &.nav-open {
        left: -$nav-sm-width;
        overflow: hidden;

        .top-bar {
            left: -$nav-sm-width;
            z-index: 2;
            cursor: pointer;
        }

        .overlay-open {
            background: $disabled-background;
            opacity: .7;
            top: 0;
            left: 0;
        }

        .footer {
            display: none;
        }

        nav {
            visibility: visible;
            left: (100 - $nav-sm-width);
            z-index: 30;
        }

        @include breakpoint(medium down) {
            left: -$nav-md-width;

            .main-navigation {
                left: (100 - $nav-md-width);
            }
        }

        @include breakpoint(small down) {
            left: -100%;

            .main-navigation {
                left: 0;
            }
        }
    }
}

.comp-navigation {

    &.nav-wrapper {
        position: relative;
        z-index: 20;

        @include breakpoint(small down) {

            &:not(.nav-has-searched) .top-bar {
                height: 104px;

                .top-bar-left .header-logo {
                    display: block;
                    width: 130px;
                }
            }
            &.nav-has-searched .top-bar .top-bar-left {
                a {
                    background-size: contain;
                    width: 20px;
                    height: 40px;
                    top: 14px;
                }
            }
        }

        .top-bar {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            border-radius: 0 0 4px 0;
            height: 140px;
            max-width: 254px;
            height: 140px;
            transition: $nav-transition;
            border-bottom: 1px solid $nav-border-color;
            z-index: 1000;
            box-shadow: 1px 2px 30px 3px rgba(0, 0, 0, .16);
            background-color: $white;
            display: flex;
            line-height: $main-navigation-height;

            @include breakpoint(small down) {
                height: 72px;
                width: 100%;
                max-width: unset;
            }

            .top-bar-left {
                margin: auto 0;
                z-index: 1;

                @include breakpoint(small down) {
                    width: $main-navigation-left-panel-small-width;
                }

                a {
                    display: block;
                    background: url('/static/assets/images/arrow.svg') no-repeat 0 rem-calc(28);
                    margin-left: rem-calc(19);
                    padding-left: rem-calc(26);
                    background-position: left top;

                    @include breakpoint(small down) {
                        background-position: 0 12px;
                    }

                    color: $top-bar-color;

                    &:hover {
                        color: $nav-website-link-hover-color;
                    }
                }

                .go-back-link {
                    display: block;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 18px;
                }

                .header-logo {
                    width: 100%;
                    max-width: rem-calc(173px);

                    @include breakpoint(small down) {
                        display: none;
                    }

                    &.has-searched {
                        margin-left: 45px;
                    }
                }
            }

            .top-bar-middle {
                position: absolute;
                width: 100%;
                left: 0;
                color: $top-bar-color;
                font-family: $heading-font-family;
                font-weight: bold;
                text-align: center;
            }

            .top-bar-right {
                position: absolute;
                right: 0;
                width: $main-navigation-right-panel-width;

                @include breakpoint(small down) {
                    width: $main-navigation-right-panel-small-width;
                }

                .dot-menu {
                    display: block;
                    width: rem-calc(5);
                    height: rem-calc(24);
                    background-image: $menu-svg;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin: 0 rem-calc(6) 0 auto;
                    padding: calc(#{$main-navigation-height} / 2) rem-calc(16);
                }
            }
        }
    }
}

.schub .comp-navigation.nav-wrapper .top-bar .top-bar-left .header-logo.has-searched {
    max-width: 50%;
    margin-left: 90px;
}

.top-bar {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: $top-bar-height-calc;
    background-color: $top-bar-background-color;
    transition: $nav-transition;
    border-bottom: 1px solid $nav-border-color;
    z-index: 1000;
    box-shadow: 1px 2px 30px 3px rgba(0, 0, 0, .16);

    .main-wrapper {
        padding: 0;
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        float: right;
    }

    %dots {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        width: 34px;
        height: 5px;
        background-color: $white;
        border-radius: 15px;
    }
}

