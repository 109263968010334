$search-input-height: rem-calc(60) !default;
$search-input-iframe-height: rem-calc(55) !default;
$top-bar-height-calc: rem-calc(68) !default;
$top-bar-height-negative-calc: rem-calc(-68) !default;
$search-input-offset-height: rem-calc(12) !default;
$search-input-bg-color: #fff !default;
$search-input-button-bg-color: #fff !default;
$input-border-color: #f7f7f7 !default;
$search-input-iframe-bg-color: #f4f4f4 !default;
$result-count-iframe-bg-color: #f4f4f4 !default;
$search-suggest-title-color: #767676 !default;
$search-suggest-border-color: #DADADA !default;
$search-suggest-shadow: #0000000F !default;

.search-input-sticky-wrapper {
    top: $top-bar-height-calc;
    width: 100%;
    //max-width: 1290px;
    z-index: 10;
    margin: 0 auto;
}

.search-input-wrapper {
    height: $search-input-height + $search-input-offset-height;
    padding-top: $search-input-offset-height;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    transition: all .5s ease-out;

    @include breakpoint(large down) {
        width: 80%;

        @include breakpoint (landscape) {
            width: 95%;
        }
    }
    @include breakpoint(medium down) {
        width: 90%;
    }
    @include breakpoint(small down) {
        width: 90%;
        padding: 0;
    }

    &.is-sticky {
        width: 100%;
        max-width: 100%;
        padding: $search-input-offset-height rem-calc(20px) 0;
    }

    &.show-background-color {
        background-color: $white;
    }

    .input-group {
        @include breakpoint(small down) {
            width: 100%;
            margin-left: -3px;
            display: list-item;
            list-style: none;
            margin-top: -20px;
            position: relative;
        }

        .search-bar-container {
            width: 100%;
        }

        .input-group-button {
            justify-content: end;
            height: 60px;
            margin-top: -60px;

            .submit-search {
                width: 60px;
                background-color: #EDEDED;
                background-image: url('/static/assets/images/search.svg');
                background-position: rem-calc(18);
                background-repeat: no-repeat;
                background-size: rem-calc(22);
                transform: rotate(90deg);
                border-radius: 4px 4px 0 0;

                @include breakpoint(small down) {
                    position: absolute;
                    height: 59px;
                    width: 59px;
                    right: 0;
                    top: 0;
                    border-radius: 4px 4px 0 0;
                }
            }

            .reset-search {
                width: 60px;
                background-color: #EDEDED;
                background-image: url('/static/assets/images/close-search.svg');
                background-position: rem-calc(20);
                background-repeat: no-repeat;
                background-size: rem-calc(18);
                border-radius: 0 4px 4px 0;

                @include breakpoint(small down) {
                    position: absolute;
                    height: 59px;
                    width: 59px;
                    right: 0;
                    top: 0;
                    border-radius: 0 4px 4px 0;
                }
            }
        }

        .input-auto-suggest {
            position: absolute;
            background-color: $search-input-button-bg-color;
            width: 100%;
            max-width: 740px;
            border-radius: 4px;
            padding: 21px;
            box-shadow: 0px 7px 15px $search-suggest-shadow;
            font-size: 16px;
            z-index: 100;
            transition: all .5s ease-out;
            margin-top: 5px;

            @include breakpoint(medium down) {
                position: relative;
            }

            @include breakpoint(small down) {
                position: absolute;
                top: 60px;
            }

            div.list-container {
                border-bottom: 1px solid $search-suggest-border-color;
            }

            div.list-container:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            div.list-container:first-child {
                margin-top: 0;
            }

            .suggest-title {
                display: inline-flex;
                font-size: 14px;
                color: $search-suggest-title-color;
                border-bottom: none;
                margin-bottom: 10px;
            }

            .list-container {
                margin-top: 20px;

                .suggest-list {
                    list-style: none;
                    margin: 0;
                    margin-bottom: 20px;
                    line-height: 30px;

                    .suggestion {
                        &:hover {
                            cursor: pointer;
                            opacity: 0.5;
                        }
                    }
                }

                .search-suggestion {
                    &--products {
                        &::before {
                            background-image: url('/static/assets/images/healthy.svg');
                            content: '';
                            display: flex;
                            width: 25px;
                            height: 25px;
                            background-repeat: no-repeat;
                        }
                    }

                    &--farms {
                        &::before {
                            background-image: url('/static/assets/images/field.svg');
                            content: '';
                            display: flex;
                            width: 25px;
                            height: 25px;
                            background-repeat: no-repeat;
                        }
                    }

                    &--locations {
                        &::before {
                            background-image: url('/static/assets/images/locations.svg');
                            content: '';
                            display: flex;
                            width: 25px;
                            height: 25px;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }
}

.search-sticky-pixel {
    width: 1px;
    height: 1px;
    position: absolute;
    top: $top-bar-height-negative-calc; // topbar height
}

.search-input-wrapper .search-bar {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 1100px;
    font-size: 16px;
    background-color: $search-input-bg-color;
    padding: 12px 20px 12px 40px;
    box-shadow: 1px 2px 30px -3px rgba(0, 0, 0, .16);
    border: 1px solid #f7f7f7;
    transition: all .5s ease-out;
    margin-right: -5px;

    @include breakpoint(small down) {
        height: 59px;
    }
}

input::placeholder {
    color: #808080;
    opacity: .5;
}

.content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: rem-calc(20px);
}

.container {
    display: table;
    width: 100%;
    position: fixed;
    left: 0;
    text-align: center;
    height: 0;
    bottom: rem-calc(15px);
    z-index: 1000;

    @include breakpoint(landscape) {
        bottom: rem-calc(18);
    }
}

.left-half {
    position: absolute;
    left: 0;
    width: 50%;
    height: 100%;
}

.right-half {
    float: right;
    position: absolute;
    right: 0;
    width: 50%;
    height: 100%;
}

.search--iframe {

    .search-bar {
        height: $search-input-iframe-height;
    }

    .search-input-wrapper {
        width: 100%;
        max-width: none;
        padding: $search-input-offset-height rem-calc(20px) 0;
        background-color: transparent;
        height: auto;
    }

    .search-input-sticky-wrapper {
        top: 0;
        background-color: $search-input-iframe-bg-color;
    }

    .search-sticky-pixel {
        left: -999em;
    }

    .result-count-label {
        background-color: $result-count-iframe-bg-color;
        position: fixed;
        bottom: 0;
        left: 0;
        height: rem-calc(47px);
        right: 0;
        padding-left: rem-calc(20px);

        @include breakpoint(small down) {
            display: flex !important;
        }
    }

    .input-group {
        margin-bottom: 0;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: static;
        height: auto;
    }

    .grid-container {
        padding: rem-calc(12px) rem-calc(20px);
        margin: 0;
        max-width: 100%;
        @include breakpoint(small down) {
            width: 100%;
            max-width: 100%;
            margin-left: rem-calc(-3px);
        }
    }

    .content-wrapper {
        position: static;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        padding: 0;
    }

    .left-half {
        order: 1;
        position: static;
        left: 0;
        width: auto;
        height: auto;
    }

    .right-half {
        order: 2;
        float: none;
        position: static;
        right: 0;
        width: auto;
        height: auto;

        .map-wrapper {
            border-radius: rem-calc(39px);
            width: rem-calc(39px);
            height: rem-calc(39px);
            background-position: center;
            background-size: rem-calc(18px);
        }
    }

    .filter-wrapper, .filter-wrapper-mobile {
        width: rem-calc(131px);
        height: rem-calc(39px);

        &.button {
            display: flex;
            justify-content: center;
            align-items: center;

            .filter-title {
                position: static;
                top: 0;
                margin-bottom: 0;
            }
        }
    }
}