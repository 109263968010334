@import 'util/util';

$global-font-size: 14px;

$buttongroup-spacing: rem-calc(10);
$button-opacity-disabled: 1;

$global-color-pick-contrast-tolerance: 100; // disables auto-picking of contrast values bg/text;

$input-radius: 5px;

$input-prefix-border: none;