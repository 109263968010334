.animation-wrapper {
    .animation-wrapper__animate-down-enter-active,
    .animation-wrapper__animate-down-exit-active,
    .animation-wrapper__animate-up-enter-active,
    .animation-wrapper__animate-up-exit-active {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    .animation-wrapper__animate-up-enter {
        transform: translateY(-100vh);
        opacity: .01;
    }

    //.animation-wrapper__animate-up-enter-done,
    .animation-wrapper__animate-up-enter-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
    }

    .animation-wrapper__animate-up-exit {
        transform: translateY(0);
        opacity: 1;
    }

    //.animation-wrapper__animate-up-exit-done,
    .animation-wrapper__animate-up-exit-active {
        transform: translateY(-100vh);
        opacity: .01;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
    }


    .animation-wrapper__animate-down-enter {
        transform: translateY(100vh);
        opacity: .01;
    }


    //.animation-wrapper__animate-down-enter-done,
    .animation-wrapper__animate-down-enter-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
    }

    .animation-wrapper__animate-down-exit {
        transform: translateY(0);
        opacity: 1;
    }



    //.animation-wrapper__animate-down-exit-done,
    .animation-wrapper__animate-down-exit-active {
        transform: translateY(100vh);
        opacity: .01;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
    }

    .animation-wrapper__animate-down-enter-done,
    .animation-wrapper__animate-down-exit-done,
    .animation-wrapper__animate-up-enter-done,
    .animation-wrapper__animate-up-exit-done {
        transform: none;
    }
}
