$main-navigation-background-color: #fff !default;
$main-navigation-border-color: #ededed !default;
$main-navigation-color: #000 !default;
$main-navigation-active-color: #ccc !default;
$main-navigation-hover-color: #000 !default;

.main-navigation {
    background-color: $main-navigation-background-color;
    box-shadow: 1px 2px 30px 3px rgba(0, 0, 0, .16);
    bottom: 0;
    display: flex;
    flex-direction: column;
    right: 0;
    left: 100%;
    position: fixed;
    top: 0;
    transition: $nav-transition;
    visibility: hidden;
    z-index: 10;

    @include breakpoint (medium down) {
        @include breakpoint (landscape) {
            overflow-y: auto;
        }
    }

    .main-navigation-list {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .main-navigation-item {
        padding: rem-calc(25) 0 rem-calc(24.5) rem-calc(16);
        border-bottom: 1px solid $main-navigation-border-color;

        a {
            color: $main-navigation-color;
            font-weight: 900;
            font-size: rem-calc(14);

            &:hover {
                color: $main-navigation-hover-color;
            }
        }

        &.main-navigation-item--title {
            text-align: center;
            font-weight: 800;
            font-size: rem-calc(14);
            position: relative;
            line-height: rem-calc(19);

            .cross {
                position: absolute;
                left: rem-calc(14);
                top: rem-calc(22);

                &::before {
                    color: $button-close-color;
                    content: '\ed6d';
                    font-size: rem-calc(25);
                    line-height: rem-calc(25);
                    font-family: $icons-font-family;
                }

                &:hover {
                    opacity: $button-hover-opacity;
                }
            }
        }
    }

    .main-navigation-language-menu {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .main-navigation-language-menu-item {
        padding: rem-calc(25.5) 0 rem-calc(24.5) rem-calc(16);
        margin-left: rem-calc(32);

        &:first-child {
            margin-left: 0;
        }

        a {
            color: $main-navigation-color;
            font-size: rem-calc(14);
            font-weight: 900;
        }

        &.active {
            a {
                color: $main-navigation-active-color;
            }
        }
    }

    .main-navigation-bottom {
        position: fixed;
        bottom: 0;
        max-height: 132px;
        height: 132px;
        width: 100%;

        @include breakpoint(medium up) {
            width: 65%;
        }

        @include breakpoint(large up) {
            width: 35%;
        }

        @include breakpoint (medium down) {
            @include breakpoint (landscape) {
                width: 100%;
                position: static;
            }
        }

        border-top: 1px solid $main-navigation-border-color;
        list-style: none;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        margin: 0;

        li {
            width: 30%;
            margin-right: 2.5%;
            margin-left: 2.5%;
        }

        .logo-ml-middle {
            span {
                display: block;
                margin: 0 auto;
            }
        }
    }
}