@import '../abstracts/foundation-settings';
@import '../abstracts/variables';

@import 'client/scss/main';

.comp-hofsearch-map {
    .soldout-info-map {
        display: none;
    }
}
