// From https://projects.lukehaas.me/css-loaders/

.loader-container {
    width: 100%;
    position: relative;
    height: 200px;

    &.loader-container--small {
        height: 25px;
    }
}

.loader {
    position: absolute;
    top: 0.5em;
    left: 50%;
    margin-left: -0.5em;
    display: block;
    width: 1em;
    height: 1em;
    font-size: 0;
    border-radius: 50%;
    background-image: linear-gradient(to right, $body-font-color 10%, rgba(15, 17, 21, 0) 42%);
    animation: load3 1.2s infinite cubic-bezier(0.7, 0.3, 0.3, 0.7);
    transform: translateZ(0);
}

.loader::before {
    width: 50%;
    height: 50%;
    background: $body-font-color;
    border-radius: 100% 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader::after {
    background-color: $white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader--small {
    font-size: 16px;
}

.loader--medium {
    font-size: 30px;
}

.loader--large {
    font-size: 40px;
}

.loader--huge {
    font-size: 60px;
}

@keyframes load3 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}