$main-navigation-background-color: #fff !default;
$main-navigation-border-color: #ededed !default;
$main-navigation-color: #000 !default;
$main-navigation-active-color: #ccc !default;
$main-navigation-hover-color: #2846AA !default;

.footer {
    background: #007844;
    padding: 30px 15px;
    margin-top: 70px;
    position: relative;

    @include breakpoint(medium up) {
        padding: 50px 75px;
    }

    h2 {
        color: white;
        font-size: 20px;
        font-family: 'TheMixC5-Bold';
        margin-bottom: 20px;
    }

    .footer-navigation-list {
        margin: 0;
        list-style-type: none;
        padding: 6px 0px;
    }

    .footer-navigation-list a {
        color: white;
        font-size: 16px;
        font-family: 'TheMixC5-Plain';
        line-height: 2;
    }

    .footer-navigation-item a {
        &:hover {
            color: white;
            border-bottom: 1px solid white;
        }
    }

    .footer-language-menu {
        @include breakpoint(medium up) {
            text-align: right;

            margin-top: 0;
            margin-bottom: 80px;
        }


        list-style-type: none;
        margin: 50px 0 30px 0;
    }

    .footer-language-menu-item {
        display: inline-block;
        padding: 0 16px;

        &:first-child {
            padding-left: 0;
        }

        @include breakpoint(medium up) {
            &:first-child {
                padding-left: 16px;
            }
            &:last-child {
                padding-right: 0;
            }
        }

        a {
            font-size: 16px;
            color: white;
            font-family: 'TheMixC5-Plain';

            &:hover {
                color: white;
                border-bottom: 1px solid white;
            }
        }

        &.active {
            opacity: 0.4;
        }
    }

    .social-media {
        clear: both;


        @include breakpoint(medium up) {
            text-align: right;
        }

        a {
            margin: 0 9px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            svg {
                fill: #fff;
            }

            &:hover svg path {
                fill: $main-navigation-hover-color;
            }


        }

        margin-bottom: 45px;
    }

    .copyright {
        margin-top: 20px;
        font-size: 12px;
        @include breakpoint(medium up) {
            text-align: right;
        }
        color: white;
        font-family: 'TheMixC5-Plain';

        @include breakpoint(small down) {
            margin-bottom: 45px;

            &.de {
                margin-bottom: 60px;
            }
        }

        @include breakpoint(medium) {
            margin-bottom: 25px;
        }
    }

    // aka "Bundesbalken"
    .footer-slogan {
        background-size: contain;
        color: #fff;
        // em so it gets the font-size form this element which is exactly the same as schweizerbauern
        height: 4.875em;
        width: 13em;
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: 700;
        font-family: Arial,sans-serif;
        font-size: 16px;

        &.de {
            background: url('/static/assets/images/bb-de-schweiz-natuerlich.svg') no-repeat bottom right;
            height: 4.5em;
        }

        &.fr {
            background: url('/static/assets/images/bb-fr-suisse-naturellement.svg') no-repeat bottom right;
        }

        &.it {
            background: url('/static/assets/images/bb-it-svizzera-naturalemente.svg') no-repeat bottom right;
        }
    }
}

.search--iframe {
    .footer {
        display: none;
    }
}