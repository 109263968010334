$button-farmsearch-share-location-background-color: #FBEC53 !default;
$button-farmsearch-share-location-text-color: #fff !default;

.location-share-comp {
  padding-right: rem-calc(80);

  .button--farm-search-share-location {
    @include button-style($button-farmsearch-share-location-background-color, $button-farmsearch-share-location-text-color);
    transition: .5s;

    &.share-location-wrapper {
      right: 0;
      position: absolute;
      bottom: 0;
      top: -200px;
      text-align: center;
      border-radius: 4px;
      float: right;
      width: 61px;
      height: 61px;
      margin: 0;
      color: #fff;
      font-weight: bold;
      background-image: url('/static/assets/images/gps.svg');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: rem-calc(30);

      @include breakpoint (small down) {
        top: -100px;
      }

      &:hover {
        background-color: $button-farmsearch-share-location-background-color;
        transform: scale(120%);
      }
    }

    .map-title {
      margin-top: 5px;
    }
  }

  @include breakpoint (small down) {
    padding-right: rem-calc(70);
  }

}

.search--iframe {
  .location-share-comp .button--farm-search-share-location.share-location-wrapper {
    background-size: rem-calc(24);
    width: 2.78571rem;
    height: 2.78571rem;
  }
}