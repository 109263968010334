$button-farmsearch-filter-close-background-color: #ccc !default;
$button-farmsearch-filter-close-text-color: #ccc !default;
$filter-transition: left .2s;
$filter-sm-width: 35.75%;
$filter-reset-button-color: #000000 !default;
$filter-summary-button-bg-color: #000000 !default;
$filter-close-button-bg-color: #ccc !default;
$disabled-button-or-text-opacity: .3 !default;
$open-modal-bg-color: #ccc !default;
$filter-green: rgb(0, 120, 68) !default;
$summary-hover: #2846AA !default;
$summary-border: #061D67 !default;

#select-filter {
    right: - $filter-sm-width;
    cursor: pointer;
    transition: $filter-transition;
    background-color: $white;
    width: 375px;

    @include breakpoint(medium down) {
        width: 100%;
        height: 100%;
    }

    @include breakpoint(medium down) {
        @include breakpoint(landscape) {
            width: 100%;
            height: 100%;
        }
    }

    &.modal {
        left: unset;

        .modal__background {
            position: fixed;
            box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
        }

        .modal__content {
            top: 0;
            height: 100%;

            .filter-open {
                height: 100%;

                .grid-container {
                    height: 100%;
                    padding: 0;

                    .grid-x {
                        height: 100%;

                        .cell {
                            height: 100%;
                        }
                    }
                }
            }

            .clearfix::after {
                content: '';
                clear: both;
                display: table;
            }

            .filter-menu {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;

                .comp-scroll-box {
                    height: calc(100% - 160px);
                }

                .filter-menu-header {
                    position: relative;
                    height: rem-calc(70);

                    .filter-menu-title {
                        font-size: 14px;
                        font-family: 'TheMixC5-SemiBold';
                        font-weight: bold;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: rem-calc(15);
                    }

                    .filter-menu-link-reset {
                        float: right;
                        font-size: 14px;
                        opacity: $disabled-button-or-text-opacity;
                        color: $filter-reset-button-color;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: rem-calc(41);

                        &.ready {
                            opacity: 1;
                        }
                    }

                    .close-filter-detail-selection {
                        position: absolute;
                        left: rem-calc(37);
                        top: rem-calc(25);

                        &::before {
                            color: $button-close-color;
                            content: '\ed6d';
                            font-size: rem-calc(19);
                            line-height: rem-calc(19);
                            font-family: $icons-font-family;
                        }

                        &:hover {
                            opacity: $button-hover-opacity
                        }
                    }
                }

                .filter-menu-list {
                    list-style: none outside none;
                    font-size: 14px;
                    margin: 0;
                    padding: rem-calc(25);

                    @include breakpoint(medium down) {
                        height: 60%;
                        overflow-y: auto;

                        @include breakpoint(landscape) {
                            height: 50%;
                        }
                    }

                    &-item {
                        position: relative;
                        line-height: 70px;
                        vertical-align: middle;
                        text-align: left;
                        font-family: 'TheMixC5-SemiBold';
                        font-weight: bold;
                        padding: 0 1rem;
                        height: 70px;

                        &-rubric {
                            font-weight: normal;
                        }

                        &-text {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            margin: 0;
                        }

                        &:hover {
                            color: $summary-hover;

                            .plus-icon-button {
                                fill: $summary-hover;
                            }
                        }

                        .plus-icon-button {
                            position: absolute;
                            width: 16px;
                            top: 55%;
                            right: 1rem;
                            transform: translateY(-50%);
                            margin: 0;
                            fill: #333;
                        }
                    }
                }

                .summary-button {
                    bottom: rem-calc(15);
                    left: rem-calc(100);
                    border: 2px solid black;;
                    display: block;
                    width: rem-calc(217);
                    max-width: 90%;
                    margin: rem-calc(16) auto;
                    height: rem-calc(58);
                    border-radius: 4px;
                    opacity: $disabled-button-or-text-opacity;
                    color: black;
                    font-family: 'TheMixC5-SemiBold';
                    font-size: rem-calc(15);
                    font-weight: bold;
                    cursor: pointer;

                    @include breakpoint(medium down) {
                        width: 90%;
                        left: 5%;

                        @include breakpoint(landscape) {
                            margin: rem-calc(13) auto;
                        }
                    }

                    &.ready {
                        opacity: 1;
                        color: $summary-hover;
                        border-color: $summary-hover;

                        &:hover {
                            color: $summary-border;
                            border-color: $summary-border;
                        }
                    }

                    @include breakpoint(medium up) {
                        position: absolute;
                    }
                }
            }

            .button-group {
                .button.button--close.close {

                    &:hover {
                        opacity: $button-hover-opacity;
                    }

                    &::before, &::after {
                        position: absolute;
                        top: 19px;
                        left: 28px;
                        content: ' ';
                        height: 20px;
                        width: 2px;
                        background-color: #fff;
                        z-index: 3000;
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }

                    &::before {
                        transform: rotate(45deg);
                    }
                }
            }

            .filter-select {
                height: 100%;

                .filter-header {
                    height: 71px;
                    position: relative;

                    .filter-name {
                        font-family: 'TheMixC5-SemiBold';
                        font-weight: bold;
                        font-size: 14px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-65%, -50%);
                        text-align: center;
                        line-height: rem-calc(25);
                    }

                    .filter-reset-link {
                        float: right;
                        font-size: 14px;
                        opacity: $disabled-button-or-text-opacity;
                        color: $filter-reset-button-color;
                        right: rem-calc(41);
                        position: absolute;
                        top: 49%;
                        transform: translateY(-50%);

                        &.ready {
                            opacity: 1;
                        }
                    }

                    .close-filter-detail-selection {
                        position: absolute;
                        left: rem-calc(37);
                        top: rem-calc(25);

                        &::before {
                            color: $button-close-color;
                            content: '\ede2';
                            font-size: rem-calc(19);
                            line-height: rem-calc(19);
                            font-family: $icons-font-family;
                        }

                        &:hover {
                            opacity: $button-hover-opacity
                        }
                    }
                }

                .comp-scroll-box {
                    height: calc(100% - 160px);

                    .scroll-wrapper {
                        height: 100%;
                    }
                }

                .filter-menu {
                    list-style: none outside none;
                    font-size: 14px;
                    padding: rem-calc(25);

                    .product-filter {
                        .filter-menu-item {
                            font-weight: normal;
                            height: 51px;
                        }

                        .disabled {
                            opacity: 0.5;
                            pointer-events: none;
                        }
                    }

                    &-check {
                        margin-right: rem-calc(10);
                        accent-color: $filter-green;
                        position: relative;
                        top: 25%;

                    }

                    &-item {
                        height: 71px;
                        vertical-align: middle;
                        text-align: left;
                        font-family: 'TheMixC5-SemiBold';
                        padding: 0 1rem;

                        label {
                            cursor: pointer;
                            top: 25%;
                            position: relative;
                        }

                        &-rubric {
                            height: 60px;
                            font-weight: normal;
                            display: flex;
                            justify-content: space-between;

                            &:hover {
                                color: $summary-hover;

                                &::after {
                                    color: $summary-hover;
                                }
                            }

                            &::after {
                                color: $button-close-color;
                                content: '\edbe';
                                font-size: rem-calc(15);
                                line-height: rem-calc(50);
                                font-family: $icons-font-family;
                            }
                        }

                        &-title {
                            margin-bottom: 20px;
                            display: flex;
                            height: 20px;
                        }

                        &.selected {
                            .checkmark {
                                display: inline-block;
                                float: right;
                                margin-top: 30px;

                                &:after {
                                    /*Add another block-level blank space*/
                                    content: '';
                                    display: block;

                                    /*Make it a small rectangle so the border will create an L-shape*/
                                    width: 8px;
                                    height: 15px;

                                    /*Add a white border on the bottom and left, creating that 'L' */
                                    border: solid $filter-summary-button-bg-color;
                                    border-width: 0 4px 4px 0;

                                    /*Rotate the L 45 degrees to turn it into a checkmark*/
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }

                .summary-button {
                    bottom: rem-calc(15);
                    left: rem-calc(100);
                    border: 2px solid black;
                    display: block;
                    width: rem-calc(217);
                    max-width: 90%;
                    margin: rem-calc(16) auto;
                    height: rem-calc(58);
                    border-radius: 4px;
                    opacity: $disabled-button-or-text-opacity;
                    color: $filter-summary-button-bg-color;
                    font-family: 'TheMixC5-SemiBold';
                    font-size: rem-calc(15);
                    font-weight: bold;
                    cursor: pointer;

                    @include breakpoint(medium down) {
                        width: 90%;
                        left: 5%;

                        @include breakpoint(landscape) {
                            margin: rem-calc(13) auto;
                        }
                    }

                    &.ready {
                        opacity: 1;
                        color: $summary-hover;
                        border-color: $summary-hover;

                        &:hover {
                            color: $summary-border;
                            border-color: $summary-border;
                        }
                    }

                    @include breakpoint(medium up) {
                        position: absolute;
                    }
                }
            }
        }
    }
}

.modal-is-open-select-filter {
    #select-filter {
        right: 0;
        transition: 0.2s;
    }

    #app {
        opacity: .4;
    }

    .modal__background {
        box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
        background: $open-modal-bg-color;
        opacity: .7;
    }
}

@include breakpoint(small down) {
    .filter-wrapper {
        display: none;
    }
}

@include breakpoint(medium up) {
    .filter-wrapper-mobile {
        display: none;
    }
}

.filter-wrapper-mobile {
    .button--farm-search-filter {
        display: block;
    }
}

.filter-wrapper, .filter-wrapper-mobile {
    position: relative;
    margin-left: 7px;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    width: rem-calc(103px);
    height: 60px;
    font-weight: bolder;
    box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
    text-align: center;
    font-weight: bold;

    @include breakpoint(small down) {
        width: 100%;
        margin-left: 0;
        margin-top: 8px;
    }
}

.button--farm-search-filter {
    @include button-style($filter-green, scale-color($button-farmsearch-filter-background-color, $lightness: 5%), $white);
    transition: .2s;
    font-size: rem-calc(15);

    padding-left: 13px;
    padding-right: 13px;

    &:hover {
        background-color: $summary-hover;
    }

    &:focus {
        background-color: $summary-hover;
    }
}

.filter-title {
    position: relative;
    top: 5px;
}

.close, button {
    border-radius: 50px;
    width: 57px;
    height: 57px;
}

.button--close {
    @include button-style($button-farmsearch-filter-close-background-color, scale-color($button-farmsearch-filter-close-background-color, $lightness: 5%), $button-farmsearch-filter-close-text-color);
    transition: .5s;
    z-index: 3000;
}

.button-group {
    position: absolute;
    top: 50%;
    left: 345px;

    @include breakpoint(medium down) {
        top: -27px;
        left: 45%;
    }

    &.select-menu-open {
        opacity: 0;
    }
}

.st0 {
    fill: #333;
}