$grid-row-width: 1280px;

// imports foundation styles etc.
@import '../../node_modules/express-skeleton-frontend/scss/main';

// include further foundation properties here: by default active are:
//
@include foundation-global-styles;
@include foundation-grid;
@include foundation-xy-grid-classes;
// * foundation-accordion
// * foundation-accordion-menu
@include foundation-visibility-classes;

@include foundation-flex-classes;
@include foundation-callout;
@include foundation-float-classes;
@include foundation-button;
@include foundation-button-group;
@include foundation-form-prepostfix;
@include foundation-card;

@import './../../node_modules/sfp-react-modal/src/scss/default';
@import './components/modal-override';

//@import './../../local_modules/datamaster-farm-detail-view-2019/src/datamaster-farm-detail-view.scss';
@import './../../node_modules/datamaster-farm-detail-view/src/datamaster-farm-detail-view.scss';

// Base
@import 'base/general';
@import 'base/utils';
@import 'base/fonts';

//Components
@import '../components/animation-wrapper/animation-wrapper';
@import '../components/search-field/search-field';
@import '../components/navigation/navigation';
@import '../components/hofsearch-filter/hofsearch-filter';
@import '../components/hofsearch-map/hofsearch-map';
@import '../components/footer/index';
@import '../components/search-results/search-results';
@import '../components/search/search';
@import '../components/loader/loader';
@import '../components/main-navigation/main-navigation';
@import '../components/not-found/index';
@import '../components/search-place-info/search-place-info';
@import '../components/location-share-button/location-share-button';
@import '../components/location-map/location-map';
@import '../components/cookie-banner/index';

// Styling for landscape mode
@import '../components/search-field/_search-landscape-mode';


// Layout
@import 'layout/layout';
