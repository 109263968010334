// Colours
$white: rgb(255, 255, 255); // #ffffff
$grey: rgb(244, 244, 244); // #f4f4f4
$black: rgb(0, 0, 0); // #000000
$text-color: rgb(41, 41, 41); // #292929
$silver: rgb(196, 196, 196); // #c4c4c4
$dove-gray: rgb(112, 112, 112); // #707070
$modal-bg: rgba(33, 33, 33, .7); // #212121
$red-error: rgba(255, 116, 15, 1);

$disabled-background: rgba(174, 174, 174, 1);
$disabled-color: $white;
$disable-opacity: 0;

// Main Colours
$sbv-green: rgb(124, 182, 79); // #7cb64f
$sbv-dark-green: rgb(76, 134, 32); // #4C8620
$sbv-vomhof: rgb(92, 144, 221); // #5c90dd
$sbv-dark-vomhof: rgb(29, 99, 171); // #1d63ab
$sbv-brunch: rgb(110, 68, 71); // #754246
$sbv-dark-brunch: rgb(71, 25, 31); // #47191f
$sbv-schub: rgb(93, 126, 45); // #52801a
$sbv-dark-schub: rgb(35, 83, 0); // #235300
$sbv-stallvisite: rgb(1, 121, 188); // #0179bc
$sbv-dark-stallvisite: rgb(0, 78, 139); // #004e8b
$sbv-lockpfosten: rgb(146, 143, 111); // #928f6b
$sbv-dark-lockpfosten: rgb(100, 98, 64); // #646240
$sbv-yellow: rgb(255, 184, 25); // #ffb819
$sbv-yellow-dark: rgb(199, 136, 0); // #c78800
$sbv-blue: rgba(92, 144, 221, 1); // #5C90DD
$sbv-light-gray: rgb(252,252,252); // #FCFCFC

//active links and hover effect
$sbv-grey: rgba(170, 170, 170, 1); // #aaa

//General Theme
$general-green: rgba(162, 200, 2, 1); //#A2C802
$button-farmsearch-map-background-color: #FBEC53;
$button-farmsearch-map-text-color: $white;

$button-farmsearch-filter-background-color: $white;
$button-farmsearch-filter-text-color: $black;
$image-list-border: rgb(204, 204, 204);

$button-farmsearch-filter-close-background-color: $black;
$button-farmsearch-filter-close-text-color: $white;

$button-close-color: $black;

$nav-website-link-color: $black;
$nav-website-link-hover-color: $black;

$top-bar-background-color: $white;
$top-bar-color: $black;
$map-top-bar-bg-color: $white;

$search-input-bg-color: $white;
$search-input-iframe-bg-color: $grey;
$search-input-button-bg-color: $white;

$result-count-iframe-bg-color: $grey;

$filter-summary-button-bg-color: $black;
$filter-close-button-bg-color: $black;
$filter-reset-color: $black;

$open-modal-bg-color: $dove-gray;

$search-title-color: $white;

$farm-vomhof-info-background-color: rgb(255, 184, 25);
$farm-vomhof-info-color: rgb(255, 255, 255);

// fonts
$heading-font-family: 'Asap', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$body-font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$icons-font-family: 'Icomoon';

// input
$input-label-color: $black;
$input-focus-color: $sbv-green;
$input-bg-color: $white;
$input-border-color: $silver;
$input-font: $body-font-family;
$input-color: $black;

// select
$select-focus-color: $sbv-green;

// sizes
$top-bar-height-plain: 68;
$main-wrapper-width: rem-calc(1280);
$top-bar-height-calc: rem-calc($top-bar-height-plain);
$top-bar-height-negative-calc: rem-calc(-$top-bar-height-plain);

$search-wrapper-top-plain: 39.5;
$search-wrapper-top-negative-plain: -39.5;
$search-input-height: rem-calc(60);
$search-input-iframe-height: rem-calc(55);
$search-input-offset-height: rem-calc(12);
$search-card-gutter: rem-calc(20);

$disabled-button-or-text-opacity: .3;
$button-hover-opacity: .75;
$main-navigation-left-panel-width: rem-calc(130);
$main-navigation-right-panel-width: rem-calc(130);
$main-navigation-left-panel-small-width: rem-calc(50);
$main-navigation-right-panel-small-width: rem-calc(50);

// main navigation
$main-navigation-active-color: $sbv-grey;
$main-navigation-overlay-color: $general-green;

// farm
$farm-link-color: $black;

$search-place-link-color: $black;

// not found
$not-found-link-color: $sbv-green;

$input-prefix-color: $black;
$input-prefix-background: $white;
$input-border-color: 1px solid $white;

$button-palette: (
    primary: $sbv-green,
    secondary: $sbv-yellow
);

$button-background: #007844;
$button-hover: #2846aa;

$link-color: #007844;

$body-font-color: $text-color;

$cluster-color: #4e4e4e;
$swiss-red-bar-text-color: $white;

$brunch-membership-color: rgb(110, 68, 71);