@charset 'UTF-8';

// Open Sans

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-Semibold.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/static/assets/fonts/open-sans/OpenSans-ExtraBold.woff2') format('woff2'),
    url('/static/assets/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

// Icomoon
@font-face {
    font-family: 'Icomoon';
    src: url('/static/assets/fonts/icomoon/icomoon-ultimate.woff2') format('woff2'),
    url('/static/assets/fonts/icomoon/icomoon-ultimate.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// The Mix

@font-face {
    font-family: 'TheMixC5-SemiBold';
    src: url('/static/assets/fonts/themix/TheMixC5-6_SemiBold.woff2') format('woff2');
    src: url('/static/assets/fonts/themix/TheMixC5-6_SemiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TheMixC5-ExtraBold';
    src: url('/static/assets/fonts/themix/TheMixC5-8_ExtraBold.woff2') format('woff2');
    src: url('/static/assets/fonts/themix/TheMixC5-8_ExtraBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}