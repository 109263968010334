* {
    outline: 0;
}

html,
body {
    color: $text-color;
    line-height: rem-calc(28);
    font-family: $body-font-family;
}

body {
    overflow-y: scroll;
}