$button-farmsearch-map-background-color: #FBEC53 !default;
$button-farmsearch-map-text-color: #ccc !default;
$map-menu-background-color: #000 !default;
$cluster-color: #4e4e4e !default;
$map-transition: .7s !default;
$map-sm-width: 35.75% !default;
$map-top-bar-bg-color: #ccc !default;

$map-loader-background-color: #e4e3df !default;

$map-link-color: rgb(41, 41, 41) !default; // #292929
$map-link-hover-color: rgb(174, 174, 174) !default; // #aeaeae

.comp-hofsearch-map {
    height: 100%;    
    overflow: hidden;

    .top-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $top-bar-height-calc;
        background-color: $map-top-bar-bg-color;
        transition: $nav-transition;
        border-bottom: 1px solid $nav-border-color;
        z-index: 1000;
        box-shadow: 1px 2px 30px 3px rgba(0, 0, 0, .16);
    }

    .map-menu-wrapper {
        width: 100vw;
        height: 69px;
        overflow: hidden;
    }

    .more-info-map {
        color: $map-link-color;
        display: inline-block;
        margin-top: 4px;
        font-weight: bold;

        &:hover {
            color: $map-link-hover-color;
        }
    }

    .map-element {
        height: calc(100vh - 70px);
        top: 0;
        width: 100vw;
        overflow: hidden;
    }

    .cluster {
        opacity: .7;
        background-color: $cluster-color !important;
        border-radius: 100%;

        div {
            color: $white !important;
            font-size: 20px !important;
        }

        img {
            display: none;
        }
    }

    .map-card-title {
        text-align: center;
        position: relative;
        top: 20px;
        font-weight: 800;
        font-size: rem-calc(14);
    }

    .cross {
        position: absolute;
        left: rem-calc(14);
        top: rem-calc(21);
        font-weight: 800;

        &::before {
            color: $button-close-color;
            content: '\ed6d';
            font-size: rem-calc(25);
            line-height: rem-calc(25);
            font-family: $icons-font-family;
        }

        &:hover {
            opacity: $button-hover-opacity;
        }
    }

    .loader-container {
        background-color: $map-loader-background-color;
        height: 100%;

        .loader:after {
            background-color: $map-loader-background-color;
        }
    }
}

.button--farm-search-map {
    @include button-style($button-farmsearch-map-background-color, scale-color($button-farmsearch-map-background-color, $lightness: 20%), $button-farmsearch-map-text-color);
    transition: .5s;

    &.map-wrapper {
        right: 0;
        position: absolute;
        bottom: 0;
        text-align: center;
        border-radius: 4px;
        float: right;
        width: 61px;
        height: 61px;
        top: -125px;
        margin: 0;
        color: $white;
        font-weight: bold;
        background-image: url('/static/assets/images/map.svg');
        background-position: 15px 15px;
        background-repeat: no-repeat;

        @include breakpoint (small down) {
            top: -25px;
          }

        &:hover {
            background-color: $button-farmsearch-map-background-color;
            transform: scale(120%);
        }
    }

    .map-title {
        margin-top: 5px;
    }
}

.modal {
    .modal__content {
        top: 0;
        height: 100%;
        width: 100%;
        max-width: none;
        padding: 0;
        box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
    }

    .modal__background {
        background-color: unset;
        box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
    }

    .map-container {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
    }
}

.modal-is-open-map {
    #map {
        -webkit-transform: none;
        transform: none;
    }

    #app {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        transition: transform $map-transition linear;
        will-change: transform;
    }

    .modal__background {
        box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, .16);
    }
}

#map {
    cursor: pointer;
    -webkit-transform: translateX(103%);
    transform: translateX(103%);
    transition: transform $map-transition linear;
    will-change: transform;

    z-index: 0;
}

#app {
    -webkit-transform: none;
    transform: none;
}

.no-farms-map-link {
    color: $map-link-color;
    font-weight: 700;

    &:hover {
        color: $map-link-hover-color;
    }
}



