$sbv-outlet-box: #fff !default;
$sbv-outlet-box-shadow: 1px 2px 10px 2px rgba(0,0,0,.16) !default;

.comp-location-map {
    .marker-info-window {
        .heading {
            font-weight: 700;
        }

        .outlets {
            font-weight: normal;

            .addlinebreak {
                white-space: pre-line;
            }
        }
    }

    .outlets-number-box {
        position: absolute;
        top: rem-calc(80);
        margin-left: rem-calc(10);
        width: rem-calc(200);
        height: rem-calc(45);
        border-radius: rem-calc(3);
        background-color: $sbv-outlet-box;
        box-shadow: $sbv-outlet-box-shadow;

        .outlets-number-title {
            font-weight: 700;
            top: rem-calc(8);
            position: absolute;

            .outlets-title {
                padding: rem-calc(10);
            }
        }

        .outlets-number {
            font-weight: normal;
            position: absolute;
            top: rem-calc(7);
            right: rem-calc(20);
        }
    }
}

.gmnoprint,
.gm-style-mtc
{
    button {
        border-radius: 0px !important;
        width: 100px;
    }
}

