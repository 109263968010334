$search-card-gutter: rem-calc(20) !default;
$search-wrapper-top-plain: 39.5 !default;
$search-input-height: rem-calc(60) !default;
$search-input-offset-height: rem-calc(12) !default;
$top-bar-height-calc: rem-calc(68) !default;
$tag-background-color: #E5F1FC !default;
$tag-background-color-hover: #2846AA!default;
$text-hover-color: #FFFFFF !default;

$footer-height: rem-calc(19);

.search-header {
    .infinite-scroll-component {
        padding: 0 ($search-card-gutter/2);
        margin: 0 (-$search-card-gutter/2);
    }

    position: absolute;
    top: calc(#{$search-wrapper-top-plain} * 1%);
    width: 100%;

    @include breakpoint(landscape) {
        // @include breakpoint(large up) {
        //     top: calc((#{$search-wrapper-top-plain} + 12.5) * 1%);
        // }

        @include breakpoint(medium down) {
            top: calc((#{$search-wrapper-top-plain} + 2.5) * 1%);
        }
    }
}

.result-wrapper {
    $search-card-gutter-calc: rem-calc($search-card-gutter);
    word-break: break-word;
    margin: 50px auto 0;
    width: 100%;
    max-width: 1290px;
    min-height: calc(100vh - #{$top-bar-height-calc} - #{$search-input-height} - #{$search-input-offset-height} - #{$search-card-gutter-calc});
    //transition: 0.2s;
    position: relative;

    @include breakpoint(large down) {
        width: 100%;
    }
    @include breakpoint(1350px down) {
        width: 90%;
    }

    @include breakpoint(landscape) {

        @include breakpoint(small down) {
            margin: 30px auto 0;
        }
    }
}

.card {
    display: flex;
    border-radius: 5px;
    //transition: 1s;
    margin: 0;
    height: 100%;
    border: none;

    &:hover {
        color: $tag-background-color-hover;
    }

    .card-image-wrapper {
        width: 100%;
        position: relative;
        padding-top: 100%;
        overflow: hidden;
    }
    .card-image {
        background-size: cover;
        background-position: 50%;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transform: scale(1);
        transition: transform 0.3s;

        &:hover {
            transform: scale(120%);
        }
    }
}


.show-brunch-soldout {
    .card {
        &.soldout {
            .card-image {
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: rgba(0,0,0,0.5);
                }

                &::after {
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    color: white;
                    font-family: 'TheMixC5-Bold';
                    text-align: center;
                    font-size: 18px;
                }
            }

            &.de {
                .card-image {
                    &::after {
                        content: 'Ausgebucht';
                    }
                }
            }

            &.fr {
                .card-image {
                    &::after {
                        content: 'Complet';
                    }
                }
            }

            &.it {
                .card-image {
                    &::after {
                        content: 'Completo';
                    }
                }
            }
        }
    }

    .comp-hofsearch-map {
        .soldout-info-map {
            color: $map-link-color;
            margin-bottom: rem-calc(4);
        }
    }
}

.card-section {
    text-align: left;
    // -webkit-transition: all 1s ease-out;
    // -moz-transition: all 1s ease-out;
    //transition: all 1s ease-out;
    margin: 0;
    padding: 13px .35714rem;

    font-size: 15px;

    display: flex;
    flex-flow: row wrap;

    p {
        font-family: 'TheMixC5-Plain';
        font-size: rem-calc(16);

        @include breakpoint(small down) {
            font-size: rem-calc(14);
        }
    }

    b {
        display: block;
        display: -webkit-box;
        width: 100%;
        max-width: 100%;
        hyphens: auto;
        max-height: 55.2px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.625;
        margin: 0;
        font-size: rem-calc(18);
        font-family: 'TheMixC5-Bold';

        @include breakpoint(small down) {
            font-size: rem-calc(16);
        }
    }

    .content {
        flex: 2 100%;
    }

    .footer {
        line-height: $footer-height;
        flex: 1 100%;
        align-self: flex-end;
        font-size: rem-calc(13);
    }
}

.card-wrapper {
    //transition: all 1s ease-out;

    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$search-card-gutter/2);

    a {
        display: block;
        //width: 50%;
        padding: 0 22px 0 22px;
        margin-bottom: 20px;

        @include breakpoint(large up) {
            width: 25%;
        }

        @include breakpoint(medium down) {
            width: 33%;
        }

        @include breakpoint(small down) {
            width: 50%;
            padding: 0 11px 0 11px;
        }


    }
}

.filter-results {
    padding: 10px;

    @include breakpoint(small down) {
        padding-left: 0;
    }

    .show-more-link, .show-less-link {
        font-size: 17px;
        margin-bottom: 36px;

        @include breakpoint(small down) {
            font-size: 14px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .show-more-link {
        &::after {
            content: '\ed5d';
            font-size: rem-calc(15);
            line-height: rem-calc(19);
            font-family: $icons-font-family;
            margin-left: rem-calc(10);
        }
    }

    .show-less-link {
        &::after {
            content: '\ed5e';
            font-size: rem-calc(15);
            line-height: rem-calc(19);
            font-family: $icons-font-family;
            margin-left: rem-calc(10);
            position: absolute;
            margin-top: rem-calc(5);

            @include breakpoint(small down) {
                font-size: 14px;
            }
        }
    }
}

.filter-results .filter-results-list {
    list-style: none;
    display: inline-flex;
    margin-left: 0;
    flex-wrap: wrap;
    height: 74px;
    overflow: hidden;

    @include breakpoint(small down) {
        height: 42px;
    }

    &.opened {
        height: auto;
        overflow: visible;
    }

    .filter-tag {
        font-size: 18px;
        padding: 15px 30px 15px 28px;
        background-color: $tag-background-color;
        border-radius: 4px;
        margin-right: 19px;
        margin-bottom: 19px;
        font-weight: 700;

        @include breakpoint(small down) {
            font-size: 14px;
            padding: 10px 14px 9px 18px;
        }

        &:last-child {
            margin-right: 0;
        }

        &::after {
            color: $button-close-color;
            content: '\ed6d';
            font-size: rem-calc(17);
            line-height: rem-calc(19);
            font-family: $icons-font-family;
            padding-left: rem-calc(20);

            @include breakpoint(small down) {
                font-size: rem-calc(13);
            }
        }

        &:hover, &:hover::after {
            cursor: pointer;
            background-color: $tag-background-color-hover;
            color: $text-hover-color
        }
    }
}

.filter-soldout {
    margin-left: auto;
    margin-right: 0;
    width: rem-calc(225);
    margin-bottom: rem-calc(35);
    font-size: rem-calc(16);
    font-family: 'TheMixC5-Plain';

    &-check {
        accent-color: $filter-green;
        margin-right: rem-calc(10);
    }
}


@include breakpoint (small down) {
    .filter-soldout:after {
        clear: both;
        content: '';
        display: block;
    }

    .card-wrapper {
        clear: both;
    }

    .filter-soldout {
        margin-left: 0;
        margin-right: auto;
        margin-bottom: 20px;
    }

    .filter-soldout-check {
        margin-left: 2px;
    }

    .search-results-title {
        margin-bottom: 20px;
    }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes card-image-animation {
    0%   {background-size: 100%;}
    100% {background-size: 190%;}
  }

  /* Standard syntax */
  @keyframes card-image-animation {
    0%   {background-size: 100%;}
    100% {background-size: 190%;}
  }
