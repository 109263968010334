@media screen and (max-device-width: 900px) and (orientation: landscape) {
    .filter-wrapper, .filter-wrapper-mobile {
        width: 100%;
        margin-left: 0;
        margin-top: 8px 
    }

    .search-input-wrapper, .search .search-title-wrapper {
        width: 75%;
    }

    .search-input-wrapper .input-group {
        width: 100%;
        margin-left: -3px;
        display: list-item;
        list-style: none;
        margin-top: -20px;
        position: relative;
    }

    .search-input-wrapper .input-group .input-group-button .submit-search {
        position: absolute;
        height: 59px;
        width: 59px;
        right: 0;
        top: 0;
        border-radius: 4px 4px 0 0;
    }

    .search .search-title-wrapper .search-title {
        font-size: 55px;
    }

    .comp-navigation.nav-wrapper .top-bar {
        height: 90px ;
        max-width: 180px;
    }

    .comp-navigation.nav-wrapper .top-bar .top-bar-left .header-logo {
        width: 80%;
    }

    .search-header {
        top: 24%;
    }
    
    .content-wrapper {
        margin-top: 0px;
    }

    .search .project-boxes img {
        height: 500px;
    }
}

@media screen and (min-width: 844px) and (orientation: landscape) { 
    .location-share-comp .button--farm-search-share-location.share-location-wrapper {
        top: -141px;
    }

    .button--farm-search-map.map-wrapper {
        top: -65px;
    }
}

@media screen and (max-width: 812px) and (min-width: 667px) and (orientation: landscape) { 
    .search .search-title-wrapper .search-title {
        font-size: 45px;
    }

    .location-share-comp .button--farm-search-share-location.share-location-wrapper {
        top: -184px;
    }

    .button--farm-search-map.map-wrapper {
        top: -109px;
    }
}

@media screen and (max-width: 667px) and (min-width: 660px) and (orientation: landscape) { 
    .search .search-title-wrapper .search-title {
        font-size: 35px;
    }

    .location-share-comp .button--farm-search-share-location.share-location-wrapper {
        top: -140px;
    }

    .button--farm-search-map.map-wrapper {
        top: -65px;
    }

    .content-wrapper {
        margin-top: -48px;
    }
}

@media screen and (max-width: 667px) and (min-width: 601px) and (orientation: landscape) { 
    .search .search-title-wrapper .search-title {
        font-size: 25px;
    }
}

@media screen and (max-width: 639px) and (orientation: landscape) { 
    .location-share-comp .button--farm-search-share-location.share-location-wrapper {
        top: -154px;
    }

    .button--farm-search-map.map-wrapper {
        top: -80px;
    }
}

@media screen and (max-width: 600px) and (orientation: landscape) { 
    .search .search-title-wrapper .search-title {
        font-size: 25px;
    }
}